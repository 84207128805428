import React from 'react';
import { shapeColors } from '../../../../styles';
/**
 * Иконка: Календарь
 * @param {string} fill - цвет иконки
 * @param {number} width - ширина иконки
 * @param {number} height - высота иконки
 * @param {string} className - внешний класс
 */
export const CalendarIcon = ({ fill = shapeColors['white'], width = '100%', height = '100%', className = '', }) => (React.createElement("svg", { className: className, width: width, height: height, fill: fill, viewBox: "0 0 26 29" },
    React.createElement("path", { fill: fill, d: "M5.992 27.89h14.015A5.007 5.007 0 0 0 25 22.896V8.667a5.007 5.007 0 0 0-4.993-4.992h-.927V1.713A.715.715 0 0 0 18.367 1a.715.715 0 0 0-.713.713v1.962H8.346V1.713A.715.715 0 0 0 7.633 1a.715.715 0 0 0-.713.713v1.962h-.927A5.007 5.007 0 0 0 1 8.667v14.194c0 2.782 2.246 5.029 4.992 5.029Zm0-22.789h.927v2.675a.714.714 0 0 0 1.426 0V5.1h9.344v2.675c0 .392.32.713.713.713.392 0 .713-.32.713-.713V5.1h.928a3.577 3.577 0 0 1 3.566 3.566v2.889H2.426V8.667a3.577 3.577 0 0 1 3.566-3.566Zm-3.566 7.881h21.147v9.879a3.577 3.577 0 0 1-3.566 3.566H5.992a3.577 3.577 0 0 1-3.566-3.566v-9.879Z" })));
