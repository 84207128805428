import styled, { css } from 'styled-components';
import { baseRow, baseText, textColors, Game_H2 } from '../../../../styles';
export const $Placeholder = styled.span `
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0px;
  transform: translateY(10px);
  transition: 0.1s ease-in-out;
  transform-origin: left;
  display: flex;
  align-items: center;
  user-select: none;

  ${Game_H2(textColors['grey_middle'])};
`;
export const $Input = styled.input `
  position: relative;
  z-index: 1;
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  box-sizing: border-box;
  padding: 0 0px 8px 0px;
  ${Game_H2(textColors['white'])};
  border-radius: 0;
  padding-right: 20px;

  &:disabled {
    ${Game_H2('rgba(0,0,0, 0.4)')};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 20px transparent inset;
  }

  &[type='date'] {
    &::-webkit-calendar-picker-indicator {
      background: transparent;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 24px;
      height: 27px;
      outline: none;
      padding: 0;
    }

    &:not(:focus)[value='']::-webkit-datetime-edit {
      color: transparent;
    }

    /* &::-webkit-datetime-edit {
      color: initial;
    } */
  }
`;
export const $ErrorText = styled.span `
  ${baseText({ color: textColors['red'] })};
  display: flex;
  align-items: center;
  transition: 0.1s ease-in-out;

  &::before {
    content: '';
    width: 2px;
    height: 2px;
    background-color: ${textColors['red']};
    margin: 0 10px;
  }
`;
export const $PlaceholderText = styled.span `
  margin: 0;
`;
export const $InputWrapper = styled.label `
  position: relative;
  min-height: 50px;
  ${baseRow('flex-start')};
  align-items: flex-end;

  ${({ themeData }) => {
    var _a, _b, _c, _d, _e, _f;
    return css `
      overflow: ${(_a = themeData.base) === null || _a === void 0 ? void 0 : _a.overflow};

      ${$Input} {
        border-bottom: ${themeData.input.borderBottom};

        &:focus {
          border-bottom: ${themeData.focus.borderBottom};

          + ${$Placeholder} {
            transform: ${themeData.focus.transform};
            ${baseText({ color: themeData.focus.color })};
            line-height: ${themeData.focus.lineHeight};
            display: ${themeData.focus.display};
          }
        }

        &:disabled {
          border-bottom: ${(_b = themeData.disabled) === null || _b === void 0 ? void 0 : _b.borderBottom};
        }
      }

      ${$Placeholder} {
        color: ${themeData.placeholder.color};
        white-space: ${themeData.placeholder.whiteSpace};
      }

      &.error {
        ${$Placeholder} {
          color: ${(_c = themeData.error) === null || _c === void 0 ? void 0 : _c.color};
        }

        ${$ErrorText} {
          color: ${(_d = themeData.error) === null || _d === void 0 ? void 0 : _d.color};

          &::before {
            background-color: ${(_e = themeData.error) === null || _e === void 0 ? void 0 : _e.color};
          }
        }

        ${$Input}:not(:focus) {
          border-color: ${(_f = themeData.error) === null || _f === void 0 ? void 0 : _f.color};
        }
      }

      .active {
        transform: ${themeData.active.transform};
        ${baseText({ color: themeData.active.color })};
        line-height: ${themeData.active.lineHeight};
        display: ${themeData.active.display};
      }
    `;
}}

  &.input_which_icon {
    ${$Input} {
      padding-left: 30px;
    }
    ${$Placeholder} {
      left: 30px;
    }
  }

  &.error {
    ${$Input} {
      border-bottom: 1px solid ${textColors['red']};
    }

    ${$Placeholder} {
      color: ${textColors['red']};
    }

    &.full {
      ${$Input}:focus {
        & + ${$Placeholder} {
          ${$ErrorText} {
            display: none;
          }
        }
      }
    }

    &.min {
      ${$ErrorText}::before {
        display: none;
      }

      ${$Input}:not(:focus) {
        & + ${$Placeholder} {
          ${$PlaceholderText} {
            display: none;
          }
        }
      }

      ${$Input}:focus {
        & + .${$Placeholder} {
          ${$ErrorText} {
            display: none;
          }
        }
      }
    }
  }

  &.disabled {
    ${$Input} {
      border-bottom: 1px solid gray;
      cursor: default;
    }
    span {
      color: gray;
    }
  }
`;
export const $Icon = styled.span `
  width: 20px;
  height: 20px;
  position: absolute;
  top: 15px;
  left: 0;
`;
export const $Calendar = styled.svg `
  position: absolute;
  right: 0;
  bottom: 13px;
`;
