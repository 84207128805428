var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, useRef, useEffect, Fragment, useMemo } from 'react';
import { $Container, $Placeholder, $InputWrapper, $Input, $Image, $Remove, $AvatarBg, } from './_styles';
import { areObjectsEqual } from '../../../../helpers/_equality';
import { useClassNames } from '../../../../hooks/_useClassNames.hook';
function arePropsEqual(prevProps, nextProps) {
    return areObjectsEqual(prevProps, nextProps);
}
/**
 * Поле загрузки изображений.
 * @param {string} className - внешний класс
 * @param {function} onChange - обработчик события изменения значения поля ввода
 * @param {string} theme - тема инпута
 * @param {boolean} showError - показывать ли ошибку
 * @param {string} placeholder - текст блока
 * @param {string} description - текст описания
 * @param {object} otherProps - любые другие значения, передаваемые полю ввода
 */
export const ImageUpload = React.memo((_a) => {
    var { className = '', changeHandler, avatar, theme = 'primary', showError = false, errorText = 'Incorrect file. Try uploading an image', placeholder = 'Drag your photo here', description = '', showExtAvatar = false } = _a, otherProps = __rest(_a, ["className", "changeHandler", "avatar", "theme", "showError", "errorText", "placeholder", "description", "showExtAvatar"]);
    const labelRef = useRef(null);
    const inputRef = useRef(null);
    const [localValue, setLocalValue] = useState('');
    const [dragging, setDragging] = useState(false);
    const [error, setError] = useState(null);
    const [currentAvatar, setCurrentAvatar] = useState(avatar);
    const image = useMemo(() => {
        if (localValue.length && !showExtAvatar) {
            return localValue;
        }
        return (currentAvatar === null || currentAvatar === void 0 ? void 0 : currentAvatar.length) ? currentAvatar : '';
    }, [localValue, currentAvatar, showExtAvatar]);
    const labelClasses = useClassNames({
        [theme]: !!theme,
        error: !!error || !!showError,
        dragging: dragging,
        active: image.length !== 0,
    });
    const updateValue = (file) => {
        const fileReader = new FileReader();
        fileReader.onload = function () {
            if (typeof this.result === 'string') {
                if (typeof changeHandler === 'function') {
                    changeHandler(this.result);
                }
                setLocalValue(this.result);
            }
        };
        fileReader.readAsDataURL(file);
    };
    function onChange(event) {
        var _a;
        if ((_a = event.target.files) === null || _a === void 0 ? void 0 : _a.length) {
            const file = event.target.files[0];
            if (file.type.startsWith('image/')) {
                updateValue(file);
            }
        }
    }
    const handleDrag = (e) => {
        e.preventDefault();
    };
    const handleDragIn = (e) => {
        var _a;
        e.preventDefault();
        if (((_a = e === null || e === void 0 ? void 0 : e.dataTransfer) === null || _a === void 0 ? void 0 : _a.items) && e.dataTransfer.items.length > 0) {
            const file = e.dataTransfer.items[0];
            if (!file.type.startsWith('image/')) {
                setError(errorText);
            }
            else {
                setDragging(true);
            }
        }
    };
    const handleDragOut = (e) => {
        e.preventDefault();
        setError(null);
        setDragging(false);
    };
    const handleDrop = (e) => {
        var _a;
        e.preventDefault();
        e.stopPropagation();
        setError(null);
        setDragging(false);
        if (((_a = e === null || e === void 0 ? void 0 : e.dataTransfer) === null || _a === void 0 ? void 0 : _a.files) && e.dataTransfer.files.length > 0) {
            const file = e.dataTransfer.files[0];
            if (file.type.startsWith('image/') && inputRef.current) {
                updateValue(file);
            }
        }
    };
    const removeImage = () => {
        setCurrentAvatar('');
        if (typeof changeHandler === 'function') {
            changeHandler('');
        }
        if (!inputRef.current)
            return;
        inputRef.current.files = null;
        inputRef.current.value = '';
        if (typeof changeHandler === 'function') {
            changeHandler('');
        }
        setLocalValue('');
    };
    useEffect(() => {
        if (!showExtAvatar)
            return;
        setCurrentAvatar(avatar);
    }, [avatar, showExtAvatar]);
    useEffect(() => {
        setError(showError ? errorText : null);
    }, [errorText, showError]);
    useEffect(() => {
        const container = labelRef.current;
        if (!container)
            return;
        container.addEventListener('dragenter', handleDragIn);
        container.addEventListener('dragleave', handleDragOut);
        container.addEventListener('dragover', handleDrag);
        container.addEventListener('drop', handleDrop);
        return () => {
            container.removeEventListener('dragenter', handleDragIn);
            container.removeEventListener('dragleave', handleDragOut);
            container.removeEventListener('dragover', handleDrag);
            container.removeEventListener('drop', handleDrop);
        };
    }, []);
    return (React.createElement($Container, { className: className },
        React.createElement($InputWrapper, { className: labelClasses, ref: labelRef },
            React.createElement($Input, Object.assign({ ref: inputRef, type: "file", accept: "image/x-png,image/jpg,image/jpeg", onChange: onChange }, otherProps)),
            React.createElement($Placeholder, null, error ? error : placeholder),
            image.length ? (React.createElement(Fragment, null,
                React.createElement($Image, { style: { backgroundImage: `url(${image})` } }),
                theme === 'avatar' && (React.createElement($AvatarBg, null,
                    React.createElement($Image, { style: { backgroundImage: `url(${image})` } }))))) : null),
        localValue || currentAvatar ? (React.createElement($Remove, { onClick: removeImage }, "Remove photo")) : (React.createElement($Remove, null, description))));
}, arePropsEqual);
