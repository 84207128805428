import React, { useMemo } from 'react';
import { CalendarIcon } from '../..';
import { $InputWrapper, $ErrorText, $Placeholder, $PlaceholderText, $Input, $Icon, $Calendar, } from './_styles';
import { validatorInputDateThemeStyles, } from './_themes';
import { areObjectsEqual } from '../../../../helpers/_equality';
import { capitalizeString, syncedMoment } from '../../../../helpers/_common';
import { BaseValidation } from '../../../../utils/_validators';
import { useClassNames } from '../../../../hooks/_useClassNames.hook';
function arePropsEqual(prevProps, nextProps) {
    return areObjectsEqual(prevProps, nextProps);
}
/**
 * Поле выбора даты.
 * @param {string} value - текущее значение поля ввода
 * @param {object} createRef - создает ссылку на поле ввода (ref)
 * @param {string} placeholder- динамический плейсхолдер типа material
 * @param {string} className - внешний класс
 * @param {function} onChange - обработчик события изменения значения поля ввода
 * @param {string} name - имя поля ввода
 * @param {string} theme - тема инпута
 * @param {string} errorText - текст ошибки
 * @param {boolean} showError  - выводить ли ошибку
 * @param {string} errorTheme - вариант отображения ошибки
 * @param {boolean} disabled - блокирует доступ и изменение поля ввода
 * @param {function} validateFromProps - функция для валидации
 */
export const ValidatorInputDate = React.memo(({ icon, value, createRef, placeholder = '', className = '', name = '', onChange, theme = 'primary', showError = false, errorTheme = 'full', validateFromProps, disabled = false, }) => {
    const currentDate = syncedMoment().format('YYYY-MM-DD');
    const minDate = '1900-01-01';
    const themeData = useMemo(() => {
        return validatorInputDateThemeStyles[theme];
    }, [theme]);
    const localValue = BaseValidation({
        defaultValue: value || '',
        validation: validateFromProps,
        changeEvent: onChange,
    });
    const isValue = (!!value && !!value.toString().length) || localValue.value !== '';
    const inputWrapperClasses = useClassNames({
        ['input_which_icon']: !!icon,
        [theme]: !!theme,
        ['error']: showError || !!localValue.error,
        ['disabled']: !!disabled,
        [errorTheme]: (showError || !!localValue.error) && !!errorTheme,
    });
    return (React.createElement($InputWrapper, { className: `${inputWrapperClasses} ${className}`, themeData: themeData },
        icon && React.createElement($Icon, null, icon),
        React.createElement($Input, { value: localValue.value, onChange: localValue.onChange, ref: createRef, placeholder: "", name: name, max: currentDate, min: minDate, type: "date", disabled: disabled }),
        React.createElement($Placeholder, { className: isValue ? 'active' : '' },
            React.createElement($PlaceholderText, null, capitalizeString(placeholder)),
            !!localValue.error && React.createElement($ErrorText, null, localValue.error)),
        React.createElement($Calendar, { as: CalendarIcon, width: 24, height: 27 })));
}, arePropsEqual);
