import styled from 'styled-components';
import { Game_H2, textColors, baseRow, screenSizes, baseText } from '../../../../styles';
export const $Container = styled.div `
  width: 100%;
  height: 100%;
`;
export const $Placeholder = styled.span `
  max-width: 150px;
  ${Game_H2('rgba(255,255,255,0.5)')};
  text-align: center;
  transition: 0.1s;
  user-select: none;
  pointer-events: none;
`;
export const $InputWrapper = styled.label `
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 416px;
  max-height: 348px;
  border: 1px dashed rgba(255, 255, 255, 0.5);
  /* background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMDEgMjAxIj48ZGVmcz48c3R5bGU+LnByZWZpeF9fY2xzLTEsLnByZWZpeF9fY2xzLTJ7ZmlsbDpub25lO3N0cm9rZTojZmZmO3N0cm9rZS1taXRlcmxpbWl0OjEwO29wYWNpdHk6LjJ9LnByZWZpeF9fY2xzLTJ7c3Ryb2tlLWRhc2hhcnJheToxMS41NyAxMC42MX08L3N0eWxlPjwvZGVmcz48ZyBpZD0icHJlZml4X19MYXllcl8yIiBkYXRhLW5hbWU9IkxheWVyIDIiPjxnIGlkPSJwcmVmaXhfX0xheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj48cGF0aCBjbGFzcz0icHJlZml4X19jbHMtMSIgZD0iTTIwMC41IDE5NC41djZoLTYiLz48cGF0aCBjbGFzcz0icHJlZml4X19jbHMtMiIgZD0iTTE4My44OSAyMDAuNUgxMS44Ii8+PHBhdGggY2xhc3M9InByZWZpeF9fY2xzLTEiIGQ9Ik02LjUgMjAwLjVoLTZ2LTYiLz48cGF0aCBjbGFzcz0icHJlZml4X19jbHMtMiIgZD0iTS41IDE4My44OVYxMS44Ii8+PHBhdGggY2xhc3M9InByZWZpeF9fY2xzLTEiIGQ9Ik0uNSA2LjV2LTZoNiIvPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMTcuMTEuNUgxODkuMiIvPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0xIiBkPSJNMTk0LjUuNWg2djYiLz48cGF0aCBjbGFzcz0icHJlZml4X19jbHMtMiIgZD0iTTIwMC41IDE3LjExVjE4OS4yIi8+PC9nPjwvZz48L3N2Zz4='); */
  overflow: hidden;
  ${baseRow()};
  cursor: pointer;
  transition: 0.1s;

  &.dragging,
  &:hover {
    border-color: white;

    ${$Placeholder} {
      color: white;
    }
  }

  &.active {
    border: none;

    ${$Placeholder} {
      display: none;
    }
  }

  &.error {
    ${$Placeholder} {
      color: ${textColors['red']} !important;
    }

    border-color: ${textColors['red']};
  }

  &.avatar {
    height: 224px;
    width: 224px;

    @media ${screenSizes.MOBILE} {
      width: auto;
      height: auto;
      border: none;

      &.active {
        width: 224px;
        height: 224px;
      }
    }
  }
`;
export const $Input = styled.input `
  position: absolute;
  bottom: -50px;
  visibility: hidden;
`;
export const $Image = styled.div `
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
export const $Remove = styled.div `
  ${baseText()};
  margin-top: 5px;
  cursor: pointer;
`;
export const $AvatarBg = styled.div `
  background-color: #000;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  ${$Image} {
    border-radius: 50%;
  }
`;
