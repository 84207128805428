import { textColors } from '../../../../styles';
export const validatorInputDateThemeStyles = {
    primary: {
        input: {
            borderBottom: `1px solid rgba(255, 255, 255, 0.5)`,
        },
        focus: {
            borderBottom: `1px solid ${textColors['white']}`,
            color: textColors['white'],
            transform: `translateY(-3px)`,
            lineHeight: `20px`,
        },
        placeholder: {
            color: `rgba(255, 255, 255, 0.5)`,
            whiteSpace: `nowrap`,
        },
        active: {
            transform: `translateY(-3px)`,
            color: textColors['white'],
            lineHeight: `20px`,
        },
    },
    secondary: {
        base: {
            overflow: `hidden`,
        },
        input: {
            borderBottom: `1px solid rgba(255, 255, 255, 0.5)`,
        },
        focus: {
            borderBottom: `1px solid ${textColors['white']}`,
            color: textColors['black'],
            transform: `translateY(-3px)`,
            lineHeight: `20px`,
        },
        disabled: {
            borderBottom: `1px solid rgba(0, 0, 0, 0.5)`,
        },
        placeholder: {
            color: `rgba(255, 255, 255, 0.5)`,
            whiteSpace: `nowrap`,
        },
        active: {
            transform: `translateY(-3px)`,
            color: textColors['black'],
            lineHeight: `20px`,
        },
    },
    login: {
        input: {
            borderBottom: `1px solid rgba(255, 255, 255, 0.5)`,
        },
        focus: {
            borderBottom: `1px solid ${textColors['white']}`,
            color: textColors['white'],
            transform: `translateY(-10px) scale(0.7)`,
            lineHeight: `20px`,
        },
        placeholder: {
            color: `rgba(255, 255, 255, 0.5)`,
        },
        error: {
            color: textColors['orange'],
        },
        active: {
            transform: `translateY(-10px)`,
            color: textColors['white'],
            lineHeight: `20px`,
        },
    },
    not_label: {
        input: {
            borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
        },
        focus: {
            borderBottom: `1px solid ${textColors['white']}`,
            display: 'none',
        },
        placeholder: {
            color: 'rgba(255, 255, 255, 0.5)',
        },
        active: {
            display: 'none',
        },
    },
    not_label_dark: {
        input: {
            borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
        },
        focus: {
            borderBottom: `1px solid ${textColors['white']}`,
            display: 'none',
        },
        placeholder: {
            color: 'rgba(255, 255, 255, 0.5)',
        },
        error: {
            color: textColors['orange'],
        },
        active: {
            display: 'none',
        },
    },
};
